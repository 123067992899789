const categories = [
  {
    name: 'Medicamentos',
    slug: 'medicamentos',
  },
  {
    name: 'Belleza',
    slug: 'belleza',
  },
  {
    name: 'Accesorios y dispositivos',
    slug: 'accesorios-y-dispositivos',
  },
  {
    name: 'Cuidado personal',
    slug: 'cuidado-personal',
  },
  {
    name: 'Nutrición y vitaminas',
    slug: 'nutricion',
  },
];

export default categories;
