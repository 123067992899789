import Image from 'next/image';
import {
  InfoDialog,
  DialogTitleTypography,
  DialogTextTypography,
  ImageContainer,
  IconContainer,
  ContentDiv,
} from './bioequivalenceDialog.styles';

interface BioequivalenceDialogProps {
  handleCloseDialog: () => void;
}

export function BioequivalenceDialog(props: BioequivalenceDialogProps) {
  const { handleCloseDialog } = props;
  return (
    <InfoDialog>
      <IconContainer>
        <Image
          layout="fill"
          src="assets/images/home/closeicon.webp"
          alt="closeIcon"
          objectFit="contain"
          onClick={handleCloseDialog}
        />
      </IconContainer>
      <ContentDiv>
        <ImageContainer>
          <Image
            layout="fill"
            src="assets/images/ISPLogo2.webp"
            alt="bioequivalence"
            objectFit="contain"
          />
        </ImageContainer>
        <DialogTitleTypography>Bioequivalencia</DialogTitleTypography>
        <DialogTextTypography>
          Un medicamento equivalente terapéutico es aquel que cumple con: tener
          el registro sanitario actualizado, tener el proceso de manufactura
          validado y haber presentado un estudio farmacocinético de
          biodisponibilidad comparativa, por lo tanto, posee la misma eficacia y
          seguridad que el producto comparador.
        </DialogTextTypography>
      </ContentDiv>
    </InfoDialog>
  );
}
