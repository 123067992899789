/*
 * File: categoriesSlider.tsx
 * Project: meki
 * File Created: Monday, 6th December 2021 3:54:51 pm
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Friday, 3rd November 2023 4:01:45 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import React, { useCallback, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import {
  ImageList,
  ImageListItem,
  Typography,
  ImageListItemProps,
} from '@mui/material';

import { getRoute } from '@utils';
import { useRouter } from 'next/router';
import { CategoriesSliderSkeleton } from '@components/Skeletons';
import { getColsAmount } from '@utils/sliderCols';
import { useWindowSize } from '@hooks/useWindowSize';
import { Category } from '@interfaces';
import { keyframes, styled } from '@mui/system';
import { useSegment } from '@hooks/useSegment';

interface SliderItemIconProps {
  slug: string;
}

interface CategoryItemDivProps {
  version?: 'version1' | 'version2'; // version1 = used in home logged out, version2 = used in home logged in
}

const CategoryIconDiv = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  height: '113px',
  width: '128px',
});
const RootDiv = styled('div')(() => ({
  display: 'flex',
  width: '100%',
}));
const CategoriesSliderImageList = styled(ImageList)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  scrollBehavior: 'smooth',
  paddingTop: 16,
  paddingLeft: '8px',
  paddingBottom: 16,
  [theme.breakpoints.down(540)]: {
    paddingTop: 16,
  },
  [theme.breakpoints.up(1200)]: {
    justifyContent: 'center',
  },
}));
const growOnClick = keyframes`
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
`;
const SlideItemImageListItemContainer = styled(ImageListItem, {
  shouldForwardProp: (prop) => prop !== 'clicked' && prop !== 'version',
})<{
  clicked: boolean;
}>((props) => ({
  height: 'auto',
  width: '200px',
  textAlign: 'center',
  cursor: 'pointer',
  padding: '0px 0px',
  margin: '0 2vw 0 2vw',
  boxSizing: 'border-box',
  flexShrink: 0,
  animation: props.clicked ? `${growOnClick} 0.25s` : undefined,
  border: 'none',
  borderRadius: '8px',
  [props.theme.breakpoints.down(768)]: {
    width: '149px',
    height: '131px !important',
  },
}));

const SlideItemImageListItem = (props: ImageListItemProps) => {
  const [clicked, setClicked] = useState(false);
  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    props.onClick?.(e);
    setClicked(true);
  };
  return (
    <SlideItemImageListItemContainer
      {...props}
      onClick={handleClick}
      clicked={clicked}
      onAnimationEnd={() => setClicked(false)}
    ></SlideItemImageListItemContainer>
  );
};

const CategoryItemDiv = styled('div', {
  shouldForwardProp: (prop) => prop !== 'version',
})<CategoryItemDivProps>(({ version }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  padding: '5px 20px',
  backgroundColor: '#FFFFFF',
  borderRadius: '6px',
  boxShadow: version == 'version1' ? '0px 0px 4px 0px #00000040' : 'none',
  zIndex: 1,
}));
const TitleTypography = styled(Typography)(({ theme }) => ({
  marginTop: 12,
  hyphens: 'manual',
  fontSize: '15px',
  fontWeight: 500,
  lineHeight: '20.5px',
  letterSpacing: '0.0928571px',
  [theme.breakpoints.up(500)]: {
    width: 115,
    fontWeight: 600,
    lineHeight: '20px',
  },
  [theme.breakpoints.up(768)]: {
    width: 125,
    fontWeight: 600,
    lineHeight: '24px',
  },
  [theme.breakpoints.up(1024)]: {
    fontSize: 19,
    width: 150,
    fontWeight: 600,
    lineHeight: '28px',
  },
}));

const cartegoriesOrderBy = [
  'medicamentos',
  'cuidado-personal',
  'nutricion',
  'belleza',
  'accesorios-y-dispositivos',
];

function SliderItemIcon({ slug }: SliderItemIconProps) {
  const [quality, setQuality] = useState(1);
  const completeLoaded = useCallback(() => {
    setQuality(100);
  }, []);
  if (slug === 'medicamentos') {
    return (
      <CategoryIconDiv>
        <Image
          layout="fill"
          objectFit="contain"
          quality={quality}
          onLoadingComplete={completeLoaded}
          src={'/assets/images/categories/bottle_with_pills.webp'}
          alt="Medicamentos"
          sizes="128px"
        />
      </CategoryIconDiv>
    );
  }
  if (slug === 'cuidado-personal') {
    return (
      <CategoryIconDiv>
        <Image
          layout="fill"
          objectFit="contain"
          quality={quality}
          onLoadingComplete={completeLoaded}
          src={'/assets/images/categories/tooth_brush.webp'}
          alt="Cuidado personal"
          sizes="128px"
        />
      </CategoryIconDiv>
    );
  }
  if (slug === 'nutricion') {
    return (
      <CategoryIconDiv>
        <Image
          layout="fill"
          objectFit="contain"
          quality={quality}
          onLoadingComplete={completeLoaded}
          src={'/assets/images/categories/vitamins.webp'}
          alt="Nutrición"
          sizes="128px"
        />
      </CategoryIconDiv>
    );
  }
  if (slug === 'belleza') {
    return (
      <CategoryIconDiv>
        <Image
          layout="fill"
          objectFit="contain"
          quality={quality}
          onLoadingComplete={completeLoaded}
          src={'/assets/images/categories/bottle_with_pipette.webp'}
          alt="Belleza"
          sizes="128px"
        />
      </CategoryIconDiv>
    );
  }
  if (slug === 'accesorios-y-dispositivos') {
    return (
      <CategoryIconDiv>
        <Image
          layout="fill"
          objectFit="contain"
          quality={quality}
          onLoadingComplete={completeLoaded}
          src={'/assets/images/categories/thermometer.webp'}
          alt="Accesorios y dispositivos"
          sizes="128px"
        />
      </CategoryIconDiv>
    );
  }
}
interface HomeCategoriesSliderProps {
  categories: Category[];
  version?: 'version1' | 'version2'; // Version 1 found in logged out, version 2 found in logged in
  location?: string;
}
export function HomeCategoriesSlider({
  categories,
  version,
  location,
}: HomeCategoriesSliderProps) {
  // Hooks init
  const router = useRouter();
  const { width } = useWindowSize();
  const { track } = useSegment();

  const sortCategoies = useMemo(() => {
    if (categories) {
      return [...categories].sort(
        (a, b) =>
          cartegoriesOrderBy.indexOf(a.slug) -
          cartegoriesOrderBy.indexOf(b.slug),
      );
    }
    return categories;
  }, [categories]);

  const cols = useMemo(() => {
    if (sortCategoies) {
      return Math.min(
        getColsAmount({
          itemWidth: 128,
          width: Math.min(width, 900),
        }),
        sortCategoies.length,
      );
    }
    return 0;
  }, [sortCategoies, width]);

  const refFirst = useRef<HTMLLIElement | null>(null);
  const refLast = useRef<HTMLLIElement | null>(null);
  const sliderRef = useRef<HTMLUListElement | null>(null);

  const formatTitle = (text: string) => {
    const title = text.split(' ');
    const formattedWords = title.map((word, index) => {
      if (word === 'y' && index !== 0) {
        return word;
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    });
    return formattedWords.join(' ');

    return title;
  };

  return (
    <RootDiv>
      {sortCategoies.length > 0 ? (
        <CategoriesSliderImageList cols={cols} gap={0} ref={sliderRef}>
          {sortCategoies.map((category, i) => {
            return (
              <SlideItemImageListItem
                key={category.slug + i.toString()}
                onClick={() => {
                  void router.push(
                    getRoute('subCategories', {
                      categorySlug: category.slug,
                    }),
                  );
                  void track('category', {
                    category: category.name,
                    location: location,
                  });
                }}
                ref={
                  i == sortCategoies.length - 1
                    ? refLast
                    : i == 0
                    ? refFirst
                    : null
                }
              >
                <CategoryItemDiv version={version}>
                  <SliderItemIcon slug={category.slug} />
                  <TitleTypography variant="subtitle2">
                    {formatTitle(category.name)}
                  </TitleTypography>
                </CategoryItemDiv>
              </SlideItemImageListItem>
            );
          })}
        </CategoriesSliderImageList>
      ) : (
        <CategoriesSliderSkeleton />
      )}
    </RootDiv>
  );
}
