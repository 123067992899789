import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

const InfoDialog = styled('dialog')({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: '25%',
  left: '0%',
  height: '382px',
  width: '328px',
  border: '1px solid #EAEAEA',
  borderRadius: '10px',
  zIndex: 1000,
});

const DialogTitleTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '17px',
  fontWeight: 600,
  lineHeight: '26px',
  letterSpacing: '-0.011em',
  textAlign: 'center',
});

const DialogTextTypography = styled(Typography)({
  fontFamily: 'Poppins',
  fontSize: '14px',
  fontWeight: 300,
  lineHeight: '23px',
  letterSpacing: '-0.011em',
  textAlign: 'center',
});

const ImageContainer = styled('div')({
  position: 'relative',
  height: '70px',
  width: '64px',
});

const IconContainer = styled('div')({
  position: 'relative',
  height: '14px',
  width: '14px',
  marginLeft: '90%',
});

const ContentDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '5px 3px 10px 3px',
});

export {
  InfoDialog,
  DialogTitleTypography,
  DialogTextTypography,
  ImageContainer,
  IconContainer,
  ContentDiv,
};
