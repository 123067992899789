/*
 * File: Skeletons.tsx
 * Project: meki
 * File Created: Tuesday, 21st December 2021 9:29:43 am
 * Author: Luis Aparicio (luis@inventures.cl)
 * -----
 * Last Modified: Thursday, 16th November 2023 1:01:35 pm
 * Modified By: Blanca Munizaga (blanca@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
import React, { useMemo } from 'react';
import { Grid, Typography, Skeleton } from '@mui/material';
import { styled } from '@mui/system';

const ContentDiv = styled('div')({
  padding: '0px 10px 0px 10px',
  '@media (min-width: 900px)': {
    maxWidth: '1280px',
    margin: 'auto',
  },
});
const SkeletonSliderDiv = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  '@media (min-width: 900px)': {
    width: '100%',
  },
  padding: '16px 0px 16px 0px',
});
const GridContainer = styled(Grid)({
  flexWrap: 'nowrap',
  overflowY: 'auto',
  width: '100%',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  '@media (min-width: 800px)': {
    justifyContent: 'space-around',
  },
});
const SliderItemGrid = styled(Grid)({
  justifyContent: 'center',
  minWidth: '111px',
  height: 'auto !important',
  cursor: 'pointer',
  marginRight: 6,
  '@media (min-width: 900px)': {
    width: 203,
    marginRight: 0,
  },
  '@media (min-width: 500px)': {
    width: 150,
  },
  '&:last-child': {
    marginRight: 0,
  },
});
const SliderCircleIconSkeleton = styled(Skeleton)({
  margin: 'auto',
  height: 64,
  width: 64,
  '@media (min-width: 1000px)': {
    height: 130,
    width: 130,
  },
  '@media (min-width: 600px)': {
    height: 80,
    width: 80,
  },
  display: 'flex',
  justifyContent: 'center',
});
const CenterSkeleton = styled(Skeleton)({
  marginLeft: 'auto',
  marginRight: 'auto',
});
const PillBoxDetailCardDiv = styled('div')({
  display: 'flex',
  margin: 16,
  height: 94,
});
const PillBoxDetailImgDiv = styled('div')({
  alignSelf: 'center',
  marginRight: 16,
});
const PillBoxDetailInfo = styled('div')({
  alignSelf: 'center',
  width: '100%',
});
const PillBoxDetailNameSkeleton = styled(Skeleton)({
  width: 'calc(100% - 20%)',
});
const PillBoxDetailConcentrationSkeleton = styled(Skeleton)({
  width: 'calc(100% - 45%)',
});
const PillBoxDetailDateSkeleton = styled(Skeleton)({
  width: 'calc(100% - 60%)',
});
const PillBoxDetailCartInfoSkeleton = styled(Skeleton)({
  width: 'calc(100% - 60%)',
});
const PillBoxDetailActionButtonDiv = styled('div')({ alignSelf: 'center' });

export function CategoriesSliderSkeleton() {
  const emptyArray = useMemo<null[]>(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    () => Array.from({ length: 5 }).map(() => null),
    [],
  );

  return (
    <ContentDiv>
      <SkeletonSliderDiv>
        <GridContainer container spacing={1}>
          {emptyArray.map((_x, i) => (
            <SliderItemGrid key={i} item>
              <SliderCircleIconSkeleton variant="circular" />
              <Typography variant="h6">
                <CenterSkeleton variant="text" width={'auto'} height={50} />
              </Typography>
            </SliderItemGrid>
          ))}
        </GridContainer>
      </SkeletonSliderDiv>
    </ContentDiv>
  );
}

export function PillBoxDetailCardSkeleton() {
  return (
    <PillBoxDetailCardDiv>
      <PillBoxDetailImgDiv>
        <CenterSkeleton variant="rectangular" width={62} height={62} />
      </PillBoxDetailImgDiv>
      <PillBoxDetailInfo>
        <PillBoxDetailNameSkeleton variant="text" height={20} />
        <PillBoxDetailConcentrationSkeleton variant="text" height={20} />
        <PillBoxDetailDateSkeleton variant="text" height={20} />
        <PillBoxDetailCartInfoSkeleton variant="text" height={20} />
      </PillBoxDetailInfo>
      <PillBoxDetailActionButtonDiv>
        <CenterSkeleton variant="circular" width={50} height={50} />
      </PillBoxDetailActionButtonDiv>
    </PillBoxDetailCardDiv>
  );
}
