/*
 * File: sliderCols.ts
 * Project: meki
 * File Created: Monday, 3rd May 2021 10:53:37 pm
 * Author: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Last Modified: Monday, 3rd May 2021 10:54:51 pm
 * Modified By: Gabriel Ulloa (gabriel@inventures.cl)
 * -----
 * Copyright 2019 - 2021 Incrementa Ventures SpA. ALL RIGHTS RESERVED
 * Terms and conditions defined in license.txt
 * -----
 * Inventures - www.inventures.cl
 */
interface GetColsAmountParams {
  width: number;
  itemWidth: number;
}
export function getColsAmount({ width, itemWidth }: GetColsAmountParams) {
  return (
    Math.trunc(width / itemWidth) +
    (width - itemWidth * Math.trunc(width / itemWidth)) / itemWidth
  );
}
